<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Asignar Patologías</strong>
      </CCardHeader>
      <CCardBody>
        <div>
        <CRow >
         <CCol sm="2">
          <select id="pathologies" class="form-select" v-on:change="getPathologyId">
                <option
                  v-for="patology in this.pathologies"
                  :key="patology.pathology_id"
                  :value="patology.pathology_id"
                >{{ patology.pathology_name }}</option>
              </select>
         </CCol>
         
         <CCol sm="6">
          <CInput id="comment" description="Comentario" type="text" size="sm" enabled @keyup="checkField()"/>
         </CCol>
         <CCol sm="2">
           <CButton class="btn btn-success active" id="assignButton" disabled @click="assignPathology()">
            Asignar
          </CButton>
         </CCol>
        </CRow>
         <CRow class="justify-content-center">
          <CCol md="9" class="p-4">
            <CAlert color="danger" dismissible :show.sync="contAlert" close-button>
              <strong>AVISO</strong>
              {{errorText}}
              <CProgress
                :max="10"
                :value="contAlert"
                height="3px"
                color="danger"
                animated
              />
            </CAlert>
          </CCol>
        </CRow>
        </div>
        
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Patologías actuales de {{user.name}} {{user.surname}}</strong>
      </CCardHeader>
      <CCardBody>
        <div v-for="(patologia, index) of this.userPathologies" :key="index">
        <CRow>
         <CCol sm="8">
          <CInput :id="'campo_'+patologia.id_pathology" description="Comentario" :label="patologia.pathology_name" :value="patologia.comment" horizontal size="sm" disabled/>      
        
         </CCol>
         <CCol sm="2">
           <CButton class="btn btn-danger active"  enabled @click="deletePathology(patologia.id_pathology)">
            Eliminar
          </CButton>
         </CCol>
        </CRow>
        </div>
      </CCardBody>
    </CCard>
    <div class="d-flex justify-content-center">
      <CButton class="btn btn-secondary" @click="back()">
        Volver
      </CButton>
    </div> 
  </div>
</template>

<script>
import {getPathologiesData, checkUser, getUserDataPerID, getUserPathologies} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
export default {
  name: "assignPathologies",
  data() {
    return {
      pathologies: [],
      userPathologies:[],
      user: [],
      URL_user_id:null,
      pathologyIDSelection:null,
      contAlert: 0,
      errorText: "Campos vacios o erroneos."
    };
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getPathologies();
      this.getUserID();
      this.getUser();
      this.getUserPathologiesData();
    },
    /**
     * Función en la que se obtienen las patologias y que también
     * llama el metodo receiveData para cargarlos.
     *
     * @returns {object[]} Las patologias obtenidas.
     */
    getPathologies() {
      let pathologyData = getPathologiesData();
      pathologyData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let element = {};
        let data=[];
        result.pathologies.forEach(pathology => {
          element = {};
          element.pathology_id = pathology.id_pathology;
          element.pathology_name = pathology.pathology_name;
          data.push(element);
        });
        this.pathologies = data;
        this.pathologyIDSelection=this.pathologies[0].pathology_id;

      });
    },
    getUserID(){
      if (!this.$route.params.id==this.URL_user_id) return;
      this.URL_user_id=this.$route.params.id;
    },
    getUser(){
      let userData = getUserDataPerID(this.URL_user_id);
      userData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.user=result.user;
      });
    },
    getUserPathologiesData(){
      let userPathologiesData = getUserPathologies(this.URL_user_id);
      userPathologiesData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.userPathologies=result.pathologies;
      });
    },
    getPathologyId(){
      this.pathologyIDSelection = event.target.value;
    },

    checkField(){
      const button = document.getElementById('assignButton');
      let limite = document.getElementById("comment").value;
      if (limite.length>0){
        button.disabled= false;
        button.style.opacity="1";
      }else{
        button.disabled= true;
        button.style.opacity=".5";
      }
    },
    assignPathology() {
      let comment = document.getElementById("comment").value;
      if (comment==""){
        this.errorText = "Introduzca un comentario.";
        this.contAlert=10;
        return;
      }
      if (comment.length>100){
        this.errorText = "El comentario es demasiado largo.";
        this.contAlert=10;
        return;
      }
      let exist=false;
      this.contAlert=0;
      this.userPathologies.forEach(pathology => {
        if (pathology.id_pathology==this.pathologyIDSelection){
          this.errorText= "Esa patología ya está asignada.";
          this.contAlert=10;
          exist=true;
        }
      });
      if (exist==true){return;}
      
      const token = localStorage.token;
        axios.post(config.BACK_IP + "/pathology/" + this.URL_user_id,
        {
          pathology_id: this.pathologyIDSelection,
          comment: comment
        }, 
        {headers: {token: token,}})
        .then(() => {
          this.getUserPathologiesData();
        })
        .catch((error) => {
          if(error.response) {
            if(error.response.status === 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    deletePathology(id) {
      const token = localStorage.token;
        axios.delete(config.BACK_IP + "/pathology/" + this.URL_user_id,{data: {pathology_id: id}, headers: {token: token}})
        .then(() => {
          this.getUserPathologiesData();
        })
        .catch((error) => {
          if(error.response) {
            if(error.response.status === 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    back() {
      this.$router.push("/user/");
    },
  },
};
</script>